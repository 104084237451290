import { Button } from "@9amhealth/shared";
import { Dialog } from "@capacitor/dialog";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import HealthSyncBloc from "src/state/HealthSyncBloc/HealthSyncBloc";
import { useBloc } from "src/state/state";
import healthkitLogo from "src/ui/assets/icons/healthkitApp.svg";
import AppPageTitle from "../AppPageTitle/AppPageTitle";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import {
  PROFILE_URL,
  ProfileMenuItem
} from "../ProfileOverview/ProfileOverview";
import OverlayBackButton from "../StyledComponents/OverlayBackButton";
import Translate from "../Translate/Translate";

const Styles = styled.div`
  img {
    width: 4.2rem;
    height: auto;
    aspect-ratio: 1;
    box-shadow: var(--light-shadow);
    border-radius: 24%;
    display: block;
    margin-bottom: 2rem;
  }
`;

const DataSyncLearnMore: FC = () => {
  const [{ available, stepsAuthorized }, { checkAndSync }] =
    useBloc(HealthSyncBloc);

  const showPrompt = async () => {
    const { value } = await Dialog.confirm({
      title: translate("dataSync.appleHealthPermissions"),
      message: translate("dataSync.appleHealthInstructions"),
      okButtonTitle: translate("dataSync.appleHealthInstructions.openHealthApp"),
      cancelButtonTitle: translate("cancel")
    });
    if (value) {
      window.open("x-apple-health://", "_blank");
    }
  };

  const updateData = async () => {
    if (stepsAuthorized === "request") {
      AppQueryPopupsController.openPopup(AppPopup.healthSyncSetup);
      return;
    }
    BlockingLoadingOverlayController.startLoading();
    try {
      if (stepsAuthorized === "granted") {
        await checkAndSync();
        BlockingLoadingOverlayController.loadingSuccess();
      }
    } catch (e) {
      reportErrorSentry(e);
      BlockingLoadingOverlayController.endLoading();
    }
  };

  return (
    <>
      <OverlayBackButton
        show
        fallbackUrl={PROFILE_URL + ProfileMenuItem.dataSync}
      />
      <AppPageTitle title={translate("dataSync.learnMore")}></AppPageTitle>
      <nine-spacer s={"lg"}></nine-spacer>
      <Styles>
        <img src={healthkitLogo} alt="HealthKit" />
        <Translate msg="dataSync.learnMore.text" markdown />
        <nine-spacer s="lg"></nine-spacer>

        <Button
          isDisabled={!available}
          onPress={() => void updateData()}
          style={{ width: "100%" }}
        >
          <Translate msg="dataSync.button.syncData" />
        </Button>
        <br />
        <Button
          onPress={() => void showPrompt()}
          isDisabled={!available}
          outline
          style={
            {
              width: "100%",
              "--inner-background": "var(--color-cream)"
            } as React.CSSProperties
          }
        >
          <Translate msg="dataSync.button.openSettings" />
        </Button>
      </Styles>
    </>
  );
};

export default DataSyncLearnMore;
